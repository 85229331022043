/* eslint-disable no-nested-ternary */
import Vue from 'vue'
import VueI18n from 'vue-i18n'

if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang', 'vn')
    document.cookie = 'lang=vn'
}

export const lang = localStorage.getItem('lang') ?? 'vn'

export function phone() {
    return lang === 'vn' ? '+84 121  xxxxxxx' : '+14845691273'
}

Vue.use(VueI18n)
function loadLocaleMessages() {
    const locales = require.context(
        './locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i,
    )
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(['vn|en'])[0]
        if (matched) {
            const locale = matched

            messages[locale] = Object.assign(messages[locale] ?? {}, locales(key))
        }
    })

    return messages
}

export default new VueI18n({
    locale: lang ?? 'vn',
    fallbackLocale: 'vn',
    globalInjection: true,
    messages: loadLocaleMessages(),
    silentTranslationWarn: true,
})
