<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <validation-provider
    v-slot='{ errors }'
    :rules="isRequire ? 'requiredCheckBox': ''"
  >
    <div>
      <div class='d-flex custom-checkbox'>
        <input
          :id='id'
          v-model='val'
          :name='"checkbox" + id'
          class='h3 custom-checkbox'
          type='checkbox'
          @click='onClick'
          @change='onChange'
        >
        <slot name='label' />

        <label
          v-if="!noLabel && label !== ''"
          :for='id'
          class='custom-checkbox__label'
        >{{ label }}</label>

        <svg
          class='svg-check'
          :style=' size ? "width:"+ size +"; height:"+ size+";" : ""'
          width='30'
          height='30'
          viewBox='0 0 36 36'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g filter='url(#filter0_i_103_15)'>
            <rect
              x='1'
              y='1'
              width='33'
              height='33'
              rx='4'
              :fill='val ? "#E4F5E6" : "#fff"'
            />
            <rect
              x='0.5'
              y='0.5'
              width='34'
              height='34'
              rx='4.5'
              stroke='#1993D8'
            />
          </g>
          <path
            :class="{ 'd-none': !val }"
            d='M10 19.0364L15.9355 24L26 11'
            stroke='#1993D8'
            stroke-width='3'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <defs>
            <filter
              id='filter0_i_103_15'
              x='0'
              y='0'
              width='38.2'
              height='38.2'
              filterUnits='userSpaceOnUse'
              color-interpolation-filters='sRGB'
            >
              <feFlood
                flood-opacity='0'
                result='BackgroundImageFix'
              />
              <feBlend
                mode='normal'
                in='SourceGraphic'
                in2='BackgroundImageFix'
                result='shape'
              />
              <feColorMatrix
                in='SourceAlpha'
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                result='hardAlpha'
              />
              <feOffset
                dx='3.2'
                dy='3.2'
              />
              <feGaussianBlur stdDeviation='2.8' />
              <feComposite
                in2='hardAlpha'
                operator='arithmetic'
                k2='-1'
                k3='1'
              />
              <feColorMatrix
                type='matrix'
                values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
              />
              <feBlend
                mode='normal'
                in2='shape'
                result='effect1_innerShadow_103_15'
              />
            </filter>
          </defs>
        </svg>

        <!--
        <svg
          class='svg-check'
          :style=' size ? "width:"+ size +"; height:"+ size+";" : ""'
          xmlns='http://www.w3.org/2000/svg'
          width='41'
          height='37'
          viewBox='0 0 41 37'
          fill='none'
        >
          <path
            d='M26.399 13H9.60103C7.61224 13 6 14.6122 6 16.601V33.399C6 35.3878 7.61224 37 9.60103 37H26.399C28.3878 37 30 35.3878 30 33.399V16.601C30 14.6122 28.3878 13 26.399 13Z'
            fill='#1993d8'
          />
          <path
            class='b'
            d='M26 9H2V33H26V9Z'
            stroke='#000'
            stroke-width='4'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            class='b checked-i'
            :class="{ 'd-none': !val }"
            d='M4 14.9135L15.0694 26L39 2'
            stroke='#000'
            stroke-width='4'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg> -->
      </div>
      <div
        v-if='errors[0]'
        class='error'
      >
        {{ errors[0] ? $t(errors[0]) : "" }}
      </div>
    </div>
  </validation-provider>

</template>

<script>
import {
    requiredCheckBox,
} from '@validations'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        ValidationProvider,
    },
    props: {
        id: {
            type: String,
            require: true,
            default: '',
        },
        value: {
            type: Boolean,
            require: true,
            default: false,
        },
        label: {
            type: String,
            require: false,
            default: '',
        },
        isRequire: {
            type: Boolean,
            require: false,
            default: false,
        },
        error: {
            type: String,
            require: false,
            default: '',
        },
        noLabel: {
            type: Boolean,
            require: false,
            default: false,
        },
        preventClick: {
            type: Boolean,
            require: false,
            default: false,
        },
        size: {
            type: String,
            require: false,
            default: null,
        },
    },
    setup() {
        return {
            requiredCheckBox,
        }
    },
    data() {
        return {
            val: this.value,
        }
    },
    methods: {
        onChange() {
            this.key++
            this.$emit('changeValue', this.val)
        },
        onClick(event) {
            if (this.preventClick) {
                event.preventDefault()
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/_variables.scss';
.svg-check {
  margin-right: 5px;
  position: absolute;
  z-index: 1;
  width: 25px;
  @media only screen and (max-width: 360px) {
    width: 20px;
    }

}
.custom-checkbox {
  position: relative;
  > input {
    vertical-align: top;
    margin: 0 3px 0 0;
    width: 36px;
    height: 36px;

    position: absolute;
    opacity: 0;
    font-size: 24px;
    font-family: 'ConsolaMonoBold', sans-serif;
    height: 58px;
    border: none;
    border-bottom: 1px solid #000;
    background: 0 0;
    z-index: 2;
    padding: 0 !important;
    transition: all 0.4s ease-out;
    @media only screen and (max-width: 1440px) {
      width: 28px;
      height: 28px !important;
    }
  }
  &__label {
    font-weight: bold;
    color: $primary;
    line-height: 30px;
    position: relative;
    padding: 0 0 0 29px;
    font-size: 18px;

    display: block !important;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    @media only screen and (max-width: 1440px) {
      line-height: 27px;
      font-size: 16px;
      line-height: 28px;
    }
    @media only screen and (max-width: 425px) {
      line-height: 23px;
      font-size: 14px;
      line-height: 28px;
    }
    @media only screen and (max-width: 360px) {
    padding: 0 0 0 24px;
    }
    &__link {
      color: $red !important;
    }
  }
}
.error {
  color: $red !important;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  transform: scale(1, 1);
  transition: 0.15s;
  width: 100%;
  color: #fe303f;
  font-size: 14px;
  transform-origin: top;
  @media only screen and (max-width: 1440px) {
    padding-left: 34px;
  }
}
</style>
