<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class='c-input'>
    <div
      v-if='localValue.length > 0'
      class='c-input__label'
    >
      {{ placeholder }}
      <div
        v-if='localValue.length > 0'
        class='c-input__label-background'
      />
    </div>
    <b-form-group :id="id + '-group'">
      <div v-if="type === 'date'">
        <p>{{ $t(dateLabel) }}</p>
        <validation-provider
          v-slot='{ errors }'
          :rules="disabled ? '' : 'required' + dopRules"
          :mode='mode'
          :name='id'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='!disabled'
            class='custom-input '
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @focus='focused = true'
            @blur='focused = false'
            @onchange='changeValue'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ $t(errors[0]) }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'amount'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <!-- CMND or CCCD -->
      <div v-else-if="type === 'cmnd'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'phone'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled ? '' : 'required|phone' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='!disabled'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus="
              () => {
                setMask('phone', localValue);
                focused = true;
              }
            "
            @blur='focused = false'
          >

          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'extraPhone'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled ? '' : 'required|phone' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='!disabled'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus="
              () => {
                setMask('phone', localValue);
                focused = true;
              }
            "
            @blur='focused = false'
          >

          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'email'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :mode='mode'
          :rules="disabled ? '' : 'email' + dopRules"
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='!disabled'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div
        v-else-if="type === 'password'"
        class='position-relative'
      >
        <validation-provider
          v-slot='{ errors }'
          :ref='id'
          :name='id'
          :mode='mode'
          :rules='"quantityPassword|" + passwordToggle ? "" : "basicTextField"'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='!disabled'
            class='custom-input'
            :type="passwordToggle ? 'password' : 'text'"
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <button
            type='button'
            class='password-toggle'
            :class="{ 'password-toggle-active': passwordToggle }"
            @click='passwordToggle = !passwordToggle'
          />
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'confirm'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :mode='mode'
          :rules="disabled ? '' : 'is:' + confirmValue + dopRules"
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :type="passwordToggle ? 'password' : 'text'"
            :placeholder='placeholder'
            :required='!disabled'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <button
            type='button'
            class='password-toggle'
            :class="{ 'password-toggle-active': passwordToggle }"
            @click='passwordToggle = !passwordToggle'
          />
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'integer'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            type='number'
            min='0'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'smsCode'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            type='number'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'passport'">
        <validation-provider
          v-slot='{ errors }'
          :ref='id'
          :name='id'
          :mode='mode'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <div v-else-if="type === 'bankAccount'">
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
          :mode='mode'
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <!-- For all fields -->
      <div v-else>
        <validation-provider
          v-slot='{ errors }'
          :name='id'
          :mode='mode'
          :rules="disabled || !isRequired ? '' : 'required' + dopRules"
        >
          <input
            :id='id'
            v-model='localValue'
            :disabled='disabled'
            :placeholder='placeholder'
            :required='isRequired'
            class='custom-input'
            :class="{
              'custom-input-failing': errors[0],
              'custom-input-outline': focused & outline,
              'custom-input-outlined': localValue.length > 0
            }"
            @input='changeValue'
            @focus='
              () => {
                focused = true;
              }
            '
            @blur='focused = false'
          >
          <div
            v-if='errors[0]'
            class='error-page-field'
          >
            {{ errors[0] ? $t(errors[0]) : "" }}
          </div>
        </validation-provider>
      </div>
      <!-- Suggestions -->
      <div
        v-if='suggestions.length > 0'
        id='suggestions'
        class='suggestions'
      >
        <div
          v-for='(suggestion, ind) in suggestions'
          :key='ind'
          @click='chooseSuggestValue(suggestion)'
        >
          <span>{{ suggestion }}</span>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { suggestionsData } from '@helper/registration/suggestions'
import i18n from '@locale'
import {
    alpha,
    bDBefore1930,
    bankName,
    dateBeforeNow,
    dateFormat,
    email,
    integer,
    is,
    length,
    min,
    phone,
    required,
} from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
    components: {
        BFormGroup,
        ValidationProvider,
    },
    props: {
        id: {
            type: String,
            require: true,
            default: '',
        },
        value: {
            type: String,
            require: false,
            default: '',
        },
        placeholder: {
            type: String,
            require: true,
            default: '',
        },
        state: {
            type: Boolean,
            require: true,
            default: true,
        },
        type: {
            type: String,
            require: false,
            default: '',
        },
        subType: {
            type: String,
            require: false,
            default: '',
        },
        dateLabel: {
            type: String,
            require: false,
            default: '',
        },
        confirmValue: {
            type: [String, Number],
            require: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            require: false,
            default: false,
        },
        isRequired: {
            type: Boolean,
            require: false,
            default: true,
        },
        dopRules: {
            type: String,
            required: false,
            default: '',
        },
        suggest: {
            type: Object,
            required: false,
            default: () => {},
        },
        outline: {
            type: Boolean,
            required: false,
            default: false,
        },
        mode: {
            type: String,
            required: false,
            default: 'eager',
        },
    },
    setup() {
        return {
            required,
            email,
            alpha,
            min,
            phone,
            is,
            integer,
            length,
            bDBefore1930,
            dateFormat,
            dateBeforeNow,
            suggestionsData,
            bankName,
        }
    },
    data() {
        return {
            localValue: this.value.toString(),
            passwordToggle: true,
            suggestions: [],
            focused: false,
            minAmount: 100,
            maxAmount: 2000,
        }
    },
    watch: {
        localValue: {
            handler(newValue) {
                this.setMask(this.type, newValue)
            },
            deep: true,
        },
        focused: {
            handler(focused) {
                if (!focused) {
                    this.correctValue(this.type)
                }
            },
            deep: true,
        },
    },
    created() {
        const input = document.querySelector('#' + this.id)
        const suggestions = document.querySelector('#suggestions')
        document.addEventListener('click', e => {
            const withinInput = e.composedPath().includes(input)
            const withinSuggestions = e.composedPath().includes(suggestions)

            if (!withinInput && !withinSuggestions) {
                this.suggestions = []
            }
        })

        if (i18n.locale === 'en') {
            this.minAmount = 50
            this.maxAmount = 800
        } else {
            this.minAmount = 1000000
            this.maxAmount = 20000000
        }
    },
    methods: {
        correctValue() {
            switch (this.type) {
            case 'amount':
                const amountAsNumber = Number(this.localValue.replace(/\D/g, ''))
                const parsedAmount = new Intl.NumberFormat("fr-FR").format(amountAsNumber)
                if (amountAsNumber > this.maxAmount) {
                    this.localValue = new Intl.NumberFormat("fr-FR").format(this.maxAmount)
                } else if (amountAsNumber < this.minAmount) {
                    this.localValue = new Intl.NumberFormat("fr-FR").format(this.minAmount)
                } else {
                    this.localValue = parsedAmount
                }
                break
            default:
                break
            }
        },
        setMask(type, value) {
            let result = ''
            switch (type) {
            case 'phone':
                const lang = localStorage.getItem('lang') ?? 'en'
                switch (lang) {
                case ('vn'):
                    // '+84 xx xxxxxxx'
                    const vi = value
                        .replace(/\D/g, '')
                        .match(/(\d{0,2})(\d{0,3})(\d{0,6})/)
                    vi[1] = '+84'
                    result = vi[1] + '-' + vi[2]
              + (vi[3] ? '-' + vi[3] : '')
              + (vi[4] ? vi[4] : '')
                    this.localValue = result
                    if (vi[4]?.length === 2) {
                        this.$emit('onComplete')
                    }
                    break
                case ('en'):
                // '+14845691273'
                    const en = value
                        .replace(/\D/g, '')
                        .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
                    en[1] = '+1'
                    result = !en[3]
                        ? en[1] + ' (' + en[2]
                        : en[1]
                  + ' ('
                  + en[2]
                  + ') '
                  + en[3]
                  + (en[4] ? '-' + en[4] : '')
                  + (en[5] ? '-' + en[5] : '')
                    this.localValue = result
                    if (en[5].length === 2) {
                        this.$emit('onComplete')
                    }
                    break
                default:
                }
                break
            case 'date':
            case 'pasportDate':
                const d = value
                    .replace(/\D/g, '')
                    .match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
                result = !d[2]
                    ? d[1]
                    : d[1] + '.' + (!d[3] ? d[2] : d[2] + '.' + d[3])
                this.localValue = result
                break
            case 'bankAccount':
                const regEx = /\d{4}(?=\d{2,3})|\d+/g
                const bA = value
                    .replace(/\D/g, '')
                    .match(regEx)
                if (bA === null) {
                    this.localValue = ''
                    return
                }
                let lengthOfBankNumber = 18

                if (this.subType) {
                    switch (this.subType) {
                    case ('ACB'):
                        lengthOfBankNumber = 8 + 1
                        break
                    case ('BIDV'):
                    case ('Techcombank'):
                        lengthOfBankNumber = 14 + 3
                        break
                    case ('Vietcombank'):
                    case ('Agribank'):
                        lengthOfBankNumber = 13 + 3
                        break
                    case ('Tpbank'):
                        lengthOfBankNumber = 11 + 2
                        break
                    case ('Dong A'):
                    case ('Vietinbank'):
                    case ('Sacombank'):
                        lengthOfBankNumber = 12 + 3
                        break
                    case ('Bac A'):
                        lengthOfBankNumber = 15 + 3
                        break
                    default:
                        break
                    }
                }
                result = bA?.join("-")
                this.localValue = result.substring(0, result < lengthOfBankNumber ? result.length : lengthOfBankNumber)
                break
            case 'cmnd':
                const card = value
                    .replace(/\D/g, '')
                result = card.substr(0, 3)
                if (card.substr(3, 3)) {
                    result += '-' + card.substr(3, 3)
                }
                if (card.substr(6, 3)) {
                    result += '-' + card.substr(6, 3)
                }
                if (card.substr(9, 3)) {
                    result += '-' + card.substr(9, 3)
                }
                this.localValue = result
                break
            case 'amount':

                // eslint-disable-next-line no-nested-ternary
                this.localValue = value > this.maxAmount ? this.maxAmount : value

                break
            default:
                break
            }
            if (!this.suggest?.fromSuggest) {
                this.$emit('changeValue', this.localValue)
            }
        },
        async changeValue() {
            this.suggestions = []
            if (this.suggest?.field) {
                const filtered = suggestionsData[this.suggest.field].filter(str => str.toLowerCase().includes(this.localValue.toLowerCase()))

                this.suggestions = filtered.slice(0, 5)
            } else if (!this.suggest?.fromSuggest) {
                this.$emit('changeValue', this.localValue)
            }
        },
        chooseSuggestValue(value) {
            this.localValue = value
            this.$emit('changeValue', this.localValue)
            this.$emit(
                'onSuggestionSelect',
                this.suggestions.filter(el => el.value === value),
            )
            this.suggestions = []
        },

    },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_variables.scss';

::placeholder {
  font-family: "Gill Sans", sans-serif;
  color: $primary;
  opacity: .5;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  font-family: "Gill Sans", sans-serif;
  color: $primary;
  opacity: .5;
}
.c-input {
  position: relative;
  z-index: 10;
  .form-group {
    @media only screen and (max-width: 690px) {
      margin-bottom: .5rem;
  }
  }

  &__label {
    color: $primary;
    font-size: 12px;
    font-family: "Gill Sans", sans-serif;
    position: absolute;
    top: -8px;
    left: 20px;
    z-index: 20;
  }
  &__label-background {
    background: $secondary-light;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 8px;
    left: 0;
    z-index: -1;
  }
}

p {
  font-size: 16px;
  margin: 0 !important;
  @media only screen and (max-width: 425px) {
    font-size: 14px;
    padding-top: 10px;
  }
}
.custom-input {

  width: 100%;
  outline: none;
  font-family: 'PaytoneOne', sans-serif;
  color: $primary;

    border-radius: 10px;
    cursor: pointer;
    background: $secondary-light;
    border: none;
    padding: 0 20px;
    height: 60px;
    margin-bottom: 5px;
  font-size: 24px;
  margin-bottom: 0;
  box-shadow: none;

  &-outlined {
    border: 1px $primary solid;
  }

  @media only screen and (max-width: 1440px) {
    font-size: 21px;
  }
  @media only screen and (max-width: 690px) {
    height: 50px;
  }
  @media only screen and (max-width: 640px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 16px;
  }

  &-failing {
    border-color: $red !important;
  }

}

.password-toggle {
  width: 29px;
  height: 60px;
    @media only screen and (max-width: 690px) {
    height: 50px;
  }
  position: absolute;
  right: 20px;
  top: 0;
  border: none;
  cursor: pointer;
  z-index: 2;
  background: 0 0;
  opacity: 1;

  transition: all 0.2s linear;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.2s linear;
    background: url(@/assets/images/dodong/system/show-pass.svg) no-repeat center;
    background-size: contain;
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.2s linear;
    opacity: 1;
    background: url(@/assets/images/dodong/system/hide-pass.svg) no-repeat center;
    background-size: contain;
  }
  &-active {
    &:after {
      opacity: 0;
    }
    &:before {
      opacity: 1;
    }
  }
}

.suggestions {
  width: 100%;
  border-radius: 10px;
  border: 1px $primary  solid;
  > div {
    background: $secondary-light;
    padding: 10px 20px;
    border-bottom: 1px $primary  solid;
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:hover {
      background: #f8f8f8;
    }
    &:not(:last-child) {
      border-bottom: 0;
    }
  }
}
</style>
