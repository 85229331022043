import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.API_URL + '/api/v1',
    withCredentials: true,
})

axiosIns.interceptors.response.use(response => response, error => {
    if (error.response?.status === 401) {
        if (process.browser) {
            localStorage.clear()
            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
        }
    } else {
        // $swal({
        //     icon: 'error',
        //     title: 'Oops...',
        //     text: 'Something went wrong!',
        //     footer: '<a href="javascript:void(0)">Why do I have this issue?</a>',
        //     customClass: {
        //         confirmButton: 'btn btn-primary',
        //     },
        //     buttonsStyling: false,
        // })
    }
    return error
})

Vue.prototype.$http = axiosIns

export default axiosIns
