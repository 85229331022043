import i18n from '@locale'
import { extend, localize, setInteractionMode } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import ru from 'vee-validate/dist/locale/ru.json'
import {
    alpha as rule_alpha,
    alpha_dash as rule_alpha_dash,
    alpha_num as rule_alpha_num,
    between as rule_between,
    confirmed as rule_confirmed,
    digits as rule_digits,
    integer as rule_integer,
    is as rule_is,
    length as rule_length,
    max_value as rule_max_value,
    min as rule_min,
    min_value as rule_min_value,
    regex as rule_regex,
    required as rule_required,
} from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { mmddyyyToddmmyyy } from '@core/utils/utils'
import {
    bankNameValidator,
    basicTextFieldValidator,
    quantityValidator,
    validatorMobilePhone,
    validatorUrlValidator,
} from './validators'
// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const min = extend('min', rule_min)

export const minValue = extend('min_value', rule_min_value)

export const maxValue = extend('max_value', rule_max_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const is = extend('is', rule_is)

export const phone = extend('phone', {
    validate: validatorMobilePhone,
    message: () => `Use format: ${i18n.locale === 'vn' ? '+84 xxx xxx-xxxx' : '+1 xxx xxx-xxxx'}`,
})

export const quantityPassword = extend('quantityPassword', {
    validate: quantityValidator,
    message: 'The password must contain at least 8 characters',
})

export const url = extend('url', {
    validate: validatorUrlValidator,
    message: 'URL is invalid',
})

export const dateBeforeNow = extend('dateBeforeNow', {
    validate: value => mmddyyyToddmmyyy(value) < new Date(new Date().toDateString()),
    message: 'Incorrect date format',
})

export const dateFormat = extend('dateFormat', {
    validate: value => {
        const regExp = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
        return regExp.test(value)
    },
    message: 'Incorrect date format',
})

export const bDBefore1930 = extend('bDBefore1930', {
    validate: date => {
        const birthDate = mmddyyyToddmmyyy(date)
        return birthDate.getFullYear() >= 1930
    },
    message: 'Incorrect date format',
})

export const requiredCheckBox = extend('requiredCheckBox', {
    validate: value => (value === true),
    message: 'Must be checked',
})

export const email = extend('email', {
    validate: value => {
        const regExp = /^[-\w.]+@([A-z0-9]+\.)+[A-z]{1,10}$/
        return regExp.test(value)
    },
    message: 'E-mail is not valid',
})

export const basicTextField = extend('basicTextField', {
    validate: basicTextFieldValidator,
    message: 'Unacceptable symbols',
})
export const bankName = extend('bankName', {
    validate: bankNameValidator,
    message: 'Select a bank from the list',
})

setInteractionMode('betterLazy', () => ({
    on: ['change', 'blur'],
    debounce: 200,
}))

// Install English and Arabic localizations.
localize({
    ru: {
        messages: ru.messages,
        names: {
            email: 'E-mail',
            password: 'Password',
            password_confirm: 'Password confirm',
            birth_date: 'Birth date',
            activate_code: 'Activate code',
            mobile_phone: 'Mobile phone',
            extra_phone: 'Extra phone',
            name: 'Full name',
            address: 'Address',
        },
        fields: {
            activate_code: {
                length: 'The {_field_} value must contain a minimum of 6 characters.',
            },
            password_confirm: {
                is: 'Password mismatch',
            },
            email: {
                required: '{_field_} must be filled in.',
            },
            phone: {
                required: '{_field_} must be filled in.',
            },

        },
    },
    en: {
        messages: en.messages,
        names: {
            email: 'Email',
            password: 'Password',
        },
        fields: {
            password: {
                min: '{_field_} is too short, you want to get hacked?',
            },
        },
    },
})
localize(i18n.locale)
