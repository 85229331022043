import { redirectAfterAuth, sendLogs } from '@core/utils/utils'
import i18n from "@locale"
import Vue from 'vue'
import VueRouter from 'vue-router'
import redirect from './redirect'
import dodong from './routes'

const routes = [
    ...redirect,
    ...dodong,
    {
        path: '*',
        redirect: i18n.locale,
    },
]
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    const result = originalPush.call(this, location, onComplete, onAbort)
    if (result) {
        return result.catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                throw err
            }
        })
    }
    return result
}

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            const yOffset = -86
            const element = document.getElementById(to.hash.replace('#', ''))
            if (!element) {
                return { x: 0, y: 0 }
            }

            const y = element.getBoundingClientRect().top + window.scrollY + yOffset
            return { x: 0, y }
        }
        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach(async (to, _, next) => {
    if (to.name === 'lk-main') {
        const route = await redirectAfterAuth()
        if (route === to.name) {
            next()
        } else {
            next({ name: route })
        }
        return
    }
    if (!to.fullPath.includes(`${i18n.locale}`)) {
        if (!localStorage.getItem('visited')) {
            localStorage.setItem('visited', true)

            const newLocale = window.location.pathname.substring(1, 3)
            i18n.locale = newLocale
            localStorage.setItem('lang', newLocale)
        } else {
            next({ path: to.fullPath.replace(/vn|en/, i18n.locale) })
        }
    }
    next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async () => {
    await sendLogs('screen_load', window.location.pathname)
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
