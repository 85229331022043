<template>
  <div
    id='app'
    class='app'
    :class='[skinClasses]'
  >
    <div class='app__wrap'>
      <c-sidebar
        v-cloak
        :side-bar-toggle='sideBarToggle'
        :sign-in='signIn'
      />
      <login
        :toggle='loginToggle'
        @closeModal='loginModalToggle(false)'
        @openModal='loginModalToggle(true)'
      />

      <reset
        :toggle='resetToggle'
        @closeModal='resetModalToggle(false)'
        @openModal='resetModalToggle(true)'
      />
      <portal-target name='app-page' />

      <component :is='layout'>
        <router-view />
      </component>
    </div>
  </div>
</template>

<script>
import { getCookie } from "@core/utils/utils"
// This will be populated in `beforeCreate` hook
import { isUserLoggedIn } from "@/auth/utils"
import Firebase from "@/libs/firebase/firebase"
import CSidebar from "@components/CSidebar.vue"
import Login from "@components/Login.vue"
import Reset from "@components/Reset.vue"
import useAppConfig from "@core/app-config/useAppConfig"
import { $themeBreakpoints, $themeColors } from "@themeConfig"
import { useCssVar } from "@vueuse/core"
import { v4 as uuidv4 } from 'uuid'

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue")

export default {
    components: {
        LayoutVertical,
        CSidebar,
        Login,
        Reset,
    },
    setup() {
        const { skinClasses } = useAppConfig()

        return {
            skinClasses,
            isUserLoggedIn,
        }
    },
    data() {
        return {
            withoutLayout: ["landing"],
            loginToggle: false,
            resetToggle: false,
            signIn: getCookie("loanWasTaken"),
            sideBarToggle: false,
        }
    },

    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        layout() {
            // if (this.$route.meta.layout === 'full') return 'layout-full'
            return `layout-vertical`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
    },
    watch: {
        "$route.hash": {
            handler(hash) {
                this.loginToggle = false
                this.resetToggle = false
                if (hash === "#signin") {
                    this.loginToggle = true
                } else if (hash === "#reset") {
                    this.resetToggle = true
                }
            },
            deep: true,
            immediate: true,
        },
    },

    beforeCreate() {
        document.documentElement.setAttribute("dir", "ltr")
        document.documentElement.setAttribute("lang", "RU")
        const metaRobots = document.createElement("meta")
        metaRobots.setAttribute("name", "robots")
        metaRobots.setAttribute("content", "noyaca")
        document.head.prepend(metaRobots)
        const metaDescription = document.createElement("meta")
        metaDescription.setAttribute("name", "description")
        metaDescription.setAttribute("content", "Dodong")
        document.head.prepend(metaDescription)
        const uuid = uuidv4()
        localStorage.setItem('uuid', uuid)
    },

    async mounted() {
        await this.setup()
    },
    methods: {
        loginModalToggle(value) {
            if (value) {
                this.$router.push({ hash: 'signin' })
            } else {
                this.$router.push({ hash: '' })
            }
        },

        resetModalToggle(value) {
            if (value) {
                this.$router.push({ hash: 'reset' })
            } else {
                this.$router.push({ hash: '' })
            }
        },
        async setup() {
            await Firebase.initializeApp()

            // Set colors in theme
            const colors = [
                "primary",
                "secondary",
                "success",
                "info",
                "warning",
                "danger",
                "light",
                "dark",
            ]

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = colors.length; i < len; i++) {
                $themeColors[colors[i]] = useCssVar(
                    `--${colors[i]}`,
                    document.documentElement,
                ).value.trim()
            }

            // Set Theme Breakpoints
            const breakpoints = ["xs", "sm", "md", "lg", "xl"]

            // eslint-disable-next-line no-plusplus
            for (let i = 0, len = breakpoints.length; i < len; i++) {
                $themeBreakpoints[breakpoints[i]] = Number(
                    useCssVar(
                        `--breakpoint-${breakpoints[i]}`,
                        document.documentElement,
                    ).value.slice(0, -2),
                )
            }
        },
    },
}
</script>

<style lang='scss'>
@import '@core/scss/base/bootstrap-extended/_variables.scss';
</style>
