<template>
  <div
    class='c-sidebar__outer'
  >
    <div
      id='sidebar'
      class='c-sidebar'
      :class='{ "c-sidebar-open": sideBarToggle }'
    >
      <div>
        <!-- // Верхний слой: прям кнопка account (слева) и крестик справа -->

        <div class='sidebar'>
          <div class='header-md header-md-sidebar clearfix'>
            <div class='position-relative'>
              <button
                type='button'
                class='close-menu'
                aria-label='close menu'
                @click='sideBarToggle = !sideBarToggle'
              >
                <span class='line-c line-c1' /><span class='line-c line-c2' />
              </button>

              <a href='/'>
                <span class='link-desc'>full-logo-white</span>
                <b-img
                  :src="require('@/assets/images/dodong/full-logo-white.svg')"
                  blank-color='#ccc'
                  class='c-sidebar__logo'
                />
              </a>
            </div>
          </div>

          <div class='list-tm-open'>
            <ul v-if='sideBar != null'>
              <li
                v-for='(sidebarItem, ind) in sideBar'
                :key='ind'
              >
                <b-link
                  @click='goToMenuItem(sidebarItem.type, sidebarItem.value)'
                >
                  {{ $t(sidebarItem.title) }}
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import User from '@/api/user'
import {
    getCookie,
    getDataFromLs,
    redirectAfterAuth,
} from '@core/utils/utils'
import { BImg, BLink } from 'bootstrap-vue'
import { BroadcastChannel } from 'broadcast-channel'

export default {
    components: {
        BImg,
        BLink,
    },
    data() {
        return {
            sideBarToggle: false,
            userInfo: {},
            signIn: getCookie('loanWasTaken') === 'true',
            sideBar: null,

        }
    },
    async beforeCreate() {
        const notificationChannel = new BroadcastChannel('toggleSideBar')
        notificationChannel.onmessage = async () => {
            if (!this.sideBarToggle) {
                this.userInfo = getDataFromLs('userInfo') ?? {
                    name: '',
                    email: '',
                    birthDate: '',
                }
            }
            this.sideBarToggle = !this.sideBarToggle
        }
    },
    async created() {
        const userData = await User.show()

        const sideBarItems = [{
            title: 'FAQ',
            value: 'faq',
            type: 'link',
        },
        {
            title: 'About',
            value: 'about',
            type: 'link',

        },
        {
            title: 'Contact us',
            value: 'contacts',
            type: 'link',

        },
        {
            title: 'Terms of use',
            value: 'terms',
            type: 'link',
        },
        {
            title: 'Privacy Policy',
            value: 'policy',
            type: 'link',
        },
        {
            title: 'How to get loan',
            value: 'how-to-get',
            type: 'link',
        },
        {
            title: 'Vacancies',
            value: 'vacancies',
            type: 'link',
        },
        {
            title: 'Ai at Dodong',
            value: 'ai',
            type: 'link',
        },
        ]

        if (userData?.statusCode === 200 && userData?.body.access_level === 100) {
            sideBarItems.unshift({
                title: 'Personal Account',
                value: 'lk-main',
                type: 'lk',
            })
        } else {
            sideBarItems.unshift({
                title: 'Sign In',
                value: 'signin',
                type: 'signIn',
            })
        }
        this.sideBar = sideBarItems
    },
    methods: {
        async goToMenuItem(type, value) {
            if (type === 'signIn') {
                this.sideBarToggle = !this.sideBarToggle
                this.$router.push({ name: 'landing', hash: '#' + value })
            } else if (type === 'lk') {
                const route = await redirectAfterAuth()
                this.sideBarToggle = !this.sideBarToggle
                this.$router.push({ name: route })
            } else if (type === 'hash') {
                this.sideBarToggle = !this.sideBarToggle
                this.$router.push({ name: 'landing', hash: '#' + value })
            } else {
                this.sideBarToggle = !this.sideBarToggle
                this.$router.push({ name: value })
            }
        },

    },

}
</script>

<style lang='scss' >
@import '@core/scss/base/bootstrap-extended/_variables.scss';
.c-sidebar {
    text-transform: uppercase;
    background-color: $primary;
    // display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100%;
    outline: 0;
    transition: 0.5s;
    left: 0;
    right: auto;
    transform: translateX(-100%);
    &__logo {
        height: 40px;
    }

    &__outer {
        z-index: 1500;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 0;
    }

    &-open {
        transition: 0.5s;
        display: flex;
        transform: translateX(0);
    }
}
</style>
