/* eslint-disable no-nested-ternary */

import { suggestionsData } from '@helper/registration/suggestions'
import i18n from '@locale'

export const validatorPositive = value => {
    if (value >= 0) {
        return true
    }
    return false
}

export const validatorMobilePhone = phone => {
    /* eslint-disable no-useless-escape */
    const localPhone = phone.replace(/[\s-()]/g, '')

    const regExp = i18n.locale === 'en' ? /^((\+1))[\d\- ]{10}$/ : /^((\+84))[\d\- ]{9}$/

    /* eslint-enable no-useless-escape */
    const validPhone = regExp.test(localPhone)
    return validPhone
}
export const validatorVIPhone = phone => {
    /* eslint-disable no-useless-escape */
    const localPhone = phone.replace(/[\s-()]/g, '')
    const regExp = /^((\+84))[\d\- ]{9}$/
    /* eslint-enable no-useless-escape */
    const validPhone = regExp.test(localPhone)
    return validPhone
}
export const validatorUSPhone = phone => {
    /* eslint-disable no-useless-escape */
    const localPhone = phone.replace(/[\s-()]/g, '')
    const regExp = /^((\+84))[\d\- ]{9}$/
    /* eslint-enable no-useless-escape */
    const validPhone = regExp.test(localPhone)
    return validPhone
}
export const validatorPhone = phone => {
    /* eslint-disable no-useless-escape */
    const localPhone = phone.replace(/[\s-()]/g, '')
    const regExp = /^((\+84))[\d\- ]{9}$/
    /* eslint-enable no-useless-escape */
    const validPhone = regExp.test(localPhone)
    return validPhone
}
export const basicTextFieldValidator = name => {
    /* eslint-disable no-useless-escape */
    // const enRegExp = /^([a-zA-Z\s\d]{1,60})$/
    // const vnRegExp = /^([aAbBcCdDeEgGhHiIkKlLmMnNoOpPqQrRsStTuUvVxXyYÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴÈÉẸẺẼÊỀẾỆỂỄÌÍỊỈĨÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠÙÚỤỦŨƯỪỨỰỬỮỲÝỴỶỸĐàáạảãâầấậẩẫăằắặẳẵèéẹẻẽêềếệểễìíịỉĩòóọỏõôồốộổỗơờớợởỡùúụủũưừứựửữỳýỵỷỹđ\s\d]{1,80})+$/
    /* eslint-disable no-useless-escape */
    // const basicTextField = (i18n.locale === 'vn' && localStorage.getItem('developer_mode') !== 'true') ? vnRegExp.test(name) : enRegExp.test(name)

    const includeSpecialCharacters = /^([~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/])/

    const basicTextField = !includeSpecialCharacters.test(name)
    return basicTextField
}
export const bankNameValidator = bank => suggestionsData.banks.includes(bank)

export const atLeastOneNumberValidator = val => {
    const regExp = /.*[0-9].*/
    return regExp.test(val)
}
export const atLeastOneCapitalValidator = val => {
    const regExp = /[A-Z]/
    return regExp.test(val)
}
export const quantityValidator = val => {
    const regExp = /[0-9a-zA-Z]{6,12}/
    return regExp.test(val)
}
export const atLeastOneSpecialCharacterValidator = val => {
    const regExp = /[@#$!?%^&+=]/
    return regExp.test(val)
}

export const validatorCreditCard = creditnum => {
    const localCreditnum = creditnum.replace(/\D/g, '')

    const masterCard = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
    const visa = /^4[0-9]{12}(?:[0-9]{3})?/

    let nCheck = 0
    if (localCreditnum && /[0-9-\s]+/.test(localCreditnum)) {
        localCreditnum.split('').forEach((v, n) => {
            let nDigit = parseInt(v, 10)

            if (!((localCreditnum.length + n) % 2) && (nDigit * 2) > 9) {
                nDigit -= 9
            }

            nCheck += nDigit
        })
    }

    return (nCheck % 10) === 0 && (masterCard.test(localCreditnum) || visa.test(localCreditnum))
}

export const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
        return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
    /* eslint-enable no-useless-escape */
    return re.test(val)
}

export const validatorRussianWordsAndNumbers = name => {
    /* eslint-disable no-useless-escape */
    const regExp = /^([А-Яа-я\-\Ёё\s\d]{1,50})$/
    /* eslint-disable no-useless-escape */
    const validRussianName = regExp.test(name)
    return validRussianName
}
export const validatorRussianName = name => {
    /* eslint-disable no-useless-escape */
    const regExp = /^([А-Яа-я\-\Ёё]{1,20})$/
    /* eslint-disable no-useless-escape */
    const validRussianName = regExp.test(name)
    return validRussianName
}

export const validatorExpDate = date => {
    const today = new Date()
    const expMonth = date.split('/')[0]
    const expYear = date.split('/')[1]
    const exDate = new Date('20' + expYear + '-' + expMonth + '-01')
    return exDate > today
}

export const validatorInn = inn => {
    const regExp = /(?!(.)\1{6,})(^[0-9]{9}$)/
    const validvalidatorInn = regExp.test(inn)
    return validvalidatorInn
}
