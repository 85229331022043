<template>
  <div>

    <div
      v-if='!isLoading'
      class='c-button'
      :class='{"c-button-outlined": type === "outlined", "c-button-disabled": disabled}'
    >
      <button
        type='button'
        :disabled='disabled'
        @click='$emit("onClick")'
      >
        <div>{{ title }}</div>
      </button>
    </div>

    <div
      v-else
      class='c-button'
    >
      <button type='button'>
        <b-spinner
          :variant='type === "fill" ? "white" : "primary"'
        />
      </button>

    </div>
  </div>

</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
    components: {
        BSpinner,
    },

    props: {
        title: {
            type: String,
            require: true,
            default: '',
        },

        isLoading: {
            type: Boolean,
            require: false,
            default: false,
        },
        type: {
            type: String,
            require: false,
            default: 'fill',
        },
        disabled: {
            type: Boolean,
            require: false,
            default: false,
        },
    },

}
</script>

<style lang='scss'>
@import "@core/scss/base/bootstrap-extended/_variables.scss";
  .c-button {
    width: 100%;
    margin: 33px 0 0;

    @media only screen and (max-width: 425px) {
      margin: 20px 0 0;
    }

    >button {
      height: 60px;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      background: $primary;
      border: none;
      > div {
        font-size: clamp(16px, 2.5vw, 24px);
        color: #fff;
        text-align: center;
        @media only screen and (max-width: 690px) {
          font-size: clamp(18px, 5vw, 24px);
        }
      }
      @media only screen and (max-width: 640px) {
        padding: 0;
        height: 48px;
      }
    }
    &-disabled {
      >button {
      background: $primary;
      opacity: 0.6;
      }
    }
    &-outlined {
      >button {
        background: #fff;
        border: 2px solid $primary;

       > div {
        color:$primary;
       }
      }
}
  }

</style>
