import i18n from "@locale"

export default [{
    path: "",
    redirect: "/" + i18n.locale,
},
{
    path: "/",
    redirect: "/" + i18n.locale,
},
{ path: '/registration/second', redirect: i18n.locale + '/registration/second' },
{ path: '/about', redirect: i18n.locale + '/about' },
{ path: '/term_of_use', redirect: i18n.locale + '/term_of_use' },
{ path: '/privacy_policy', redirect: i18n.locale + '/privacy_policy' },
{ path: '/contact_us', redirect: i18n.locale + '/contact_us' },
{ path: '/lk/main', redirect: i18n.locale + '/lk/main' },
{ path: '/vacancies', redirect: i18n.locale + '/vacancies' },
{ path: '/vacancy/id=:id', redirect: i18n.locale + '/vacancy/id=:id' },
{ path: '/ai', redirect: i18n.locale + '/ai' },
{ path: '/how-to-get', redirect: i18n.locale + '/how-to-get' },
{ path: '/faq', redirect: i18n.locale + '/faq' },

]
