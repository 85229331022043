module.exports = [{
    path: '/:locale(en|vn)/',
    name: 'landing',
    component: () => import('@/views/dodong/landing/Landing.vue'),
    meta: {
        pageTitle: 'Dodong Asia',
    },
}, {
    path: '/:locale(en|vn)/registration/second',
    name: 'second',
    meta: {
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/registration/second/SecondWrapper.vue'),
}, {
    path: '/:locale(en|vn)/registration/result',
    name: 'result',
    meta: {
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/registration/RegistrationResult.vue'),
}, {
    path: '/:locale(en|vn)/about',
    name: 'about',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/about/AboutWrapper.vue'),
}, {
    path: '/:locale(en|vn)/term_of_use',
    name: 'terms',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/termOfUse/TermOfUseWrapper.vue'),
}, {
    path: '/:locale(en|vn)/privacy_policy',
    name: 'policy',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/privacyPolicy/PrivacyPolicyWrapper.vue'),
}, {
    path: '/:locale(en|vn)/contact_us',
    name: 'contacts',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/contacts/ContactsWrapper.vue'),
}, {
    path: '/:locale(en|vn)/error-404',
    name: 'error-404',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },

}, {
    path: '/:locale(en|vn)/lk/main',
    name: 'lk-main',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/lk/Main.vue'),

}, {
    path: '/:locale(en|vn)/vacancies',
    name: 'vacancies',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/vacancies/VacanciesWrapper.vue'),

}, {
    path: '/:locale(en|vn)/vacancy/id=:id',
    name: 'vacancy',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/vacancies/Vacancy.vue'),
},

{
    path: '/:locale(en|vn)/how-to-get',
    name: 'how-to-get',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/HowToGet.vue'),
}, {
    path: '/:locale(en|vn)/ai',
    name: 'ai',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/ai/AiWrapper.vue'),
},
{
    path: '/:locale(en|vn)/faq',
    name: 'faq',
    meta: {
        hide: true,
        sitemap: {
            ignoreRoute: true,
        },
        pageTitle: 'Dodong Asia',
    },
    component: () => import('@/views/dodong/faq/FaqWrapper.vue'),
},
]
