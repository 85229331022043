var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-input"},[(_vm.localValue.length > 0)?_c('div',{staticClass:"c-input__label"},[_vm._v(" "+_vm._s(_vm.placeholder)+" "),(_vm.localValue.length > 0)?_c('div',{staticClass:"c-input__label-background"}):_vm._e()]):_vm._e(),_c('b-form-group',{attrs:{"id":_vm.id + '-group'}},[(_vm.type === 'date')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t(_vm.dateLabel)))]),_c('validation-provider',{attrs:{"rules":_vm.disabled ? '' : 'required' + _vm.dopRules,"mode":_vm.mode,"name":_vm.id},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled},domProps:{"value":(_vm.localValue)},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"onchange":_vm.changeValue,"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(_vm.$t(errors[0]))+" ")]):_vm._e()]}}],null,false,2212426502)})],1):(_vm.type === 'amount')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'cmnd')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'phone')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled ? '' : 'required|phone' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.setMask('phone', _vm.localValue);
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'extraPhone')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled ? '' : 'required|phone' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.setMask('phone', _vm.localValue);
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'email')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"mode":_vm.mode,"rules":_vm.disabled ? '' : 'email' + _vm.dopRules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'password')?_c('div',{staticClass:"position-relative"},[_c('validation-provider',{ref:_vm.id,attrs:{"name":_vm.id,"mode":_vm.mode,"rules":"quantityPassword|" + _vm.passwordToggle ? "" : "basicTextField"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [((_vm.passwordToggle ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"input":_vm.changeValue,"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.passwordToggle ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"input":_vm.changeValue,"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled,"type":_vm.passwordToggle ? 'password' : 'text'},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),_c('button',{staticClass:"password-toggle",class:{ 'password-toggle-active': _vm.passwordToggle },attrs:{"type":"button"},on:{"click":function($event){_vm.passwordToggle = !_vm.passwordToggle}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'confirm')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"mode":_vm.mode,"rules":_vm.disabled ? '' : 'is:' + _vm.confirmValue + _vm.dopRules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [((_vm.passwordToggle ? 'password' : 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"input":_vm.changeValue,"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}):((_vm.passwordToggle ? 'password' : 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"input":_vm.changeValue,"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false},"change":function($event){_vm.localValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":!_vm.disabled,"type":_vm.passwordToggle ? 'password' : 'text'},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),_c('button',{staticClass:"password-toggle",class:{ 'password-toggle-active': _vm.passwordToggle },attrs:{"type":"button"},on:{"click":function($event){_vm.passwordToggle = !_vm.passwordToggle}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'integer')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"type":"number","min":"0","disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'smsCode')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"type":"number","disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'passport')?_c('div',[_c('validation-provider',{ref:_vm.id,attrs:{"name":_vm.id,"mode":_vm.mode,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):(_vm.type === 'bankAccount')?_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1):_c('div',[_c('validation-provider',{attrs:{"name":_vm.id,"mode":_vm.mode,"rules":_vm.disabled || !_vm.isRequired ? '' : 'required' + _vm.dopRules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],staticClass:"custom-input",class:{
            'custom-input-failing': errors[0],
            'custom-input-outline': _vm.focused & _vm.outline,
            'custom-input-outlined': _vm.localValue.length > 0
          },attrs:{"id":_vm.id,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"required":_vm.isRequired},domProps:{"value":(_vm.localValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.localValue=$event.target.value},_vm.changeValue],"focus":() => {
              _vm.focused = true;
            },"blur":function($event){_vm.focused = false}}}),(errors[0])?_c('div',{staticClass:"error-page-field"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(errors[0]) : "")+" ")]):_vm._e()]}}])})],1),(_vm.suggestions.length > 0)?_c('div',{staticClass:"suggestions",attrs:{"id":"suggestions"}},_vm._l((_vm.suggestions),function(suggestion,ind){return _c('div',{key:ind,on:{"click":function($event){return _vm.chooseSuggestValue(suggestion)}}},[_c('span',[_vm._v(_vm._s(suggestion))])])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }