<template>

  <custom-modal
    key='loginModal'
    :width='600'
    :toggle='toggle'
    @closeModal="$emit('closeModal')"
    @openModal="$emit('openModal')"
  >
    <template
      #content
    >
      <div
        class='d-flex align-items-center'
      >
        <b-col
          lg='12'
          class='px-xl-2 mx-auto'
        >
          <b-card-title
            class='mb-1 font-weight-bold text-primary'
            title-tag='h2'
          >
            {{ $t('Welcome') }}! 👋
          </b-card-title>
          <b-card-text class='mb-2 text-primary'>
            {{ $t('Please sign-in to your account') }}.
          </b-card-text>

          <!-- form -->

          <b-form
            class='auth-login-form'
            @submit.prevent='login'
          >
            <!-- email -->

            <c-input
              id='E-mail'
              type='email'
              :value='user.email'
              :placeholder='$t("E-mail")'
              @changeValue='(newValue) => (user.email = newValue)'
            />

            <!-- forgot password -->

            <c-input
              id='Password'
              type='password'
              :value='user.password'
              :placeholder='$t("Password")'
              @changeValue='(newValue) => (user.password = newValue)'
            />
            <div class='options'>
              <custom-checkbox
                id='rememberme-checkbox'
                :value='user.rememberme'
                :label="$t('Remember Me')"
                @changeValue='(val) => (user.rememberme = val)'
              />
              <b-link href='/#reset'>
                <div class='forgot-label'>
                  {{ $t('Forgot Password') }}?
                </div>
              </b-link>

            </div>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id='remember-me'
                class='remember-me text-primary'
                name='checkbox-1'
              >
                {{ $t('Remember Me') }}
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit buttons -->

            <div
              v-if='sendError'
              class='error-page-field form-error'
            >
              {{ $t(sendError) }}
            </div>

            <c-button
              :title='$t("Sign in")'
              :is-loading='isLoading'
              @onClick='login'
            />

          </b-form>

          <!-- buttons -->
        </b-col>
      </div>
    </template>
  </custom-modal>
</template>

<script>
import Login from '@/api/login'
import CButton from '@components/CButton.vue'
import CInput from '@components/CInput.vue'
import CustomCheckbox from "@components/CustomCheckbox.vue"
import CustomModal from '@components/CustomModal.vue'
import { redirectAfterAuth } from "@core/utils/utils"
import {
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BLink,
} from 'bootstrap-vue'
import RandExp from 'randexp'

export default {
    components: {
        CButton,
        CInput,
        CustomModal,
        BCol,
        BCardText,
        BLink,
        BForm,
        CustomCheckbox,
        BCardTitle,
    },
    props: {
        toggle: {
            type: Boolean,
            require: true,
            default: false,
        },
    },
    setup() {
        return {
            RandExp,
        }
    },
    data() {
        return {
            user: {
                email: '',
                password: '',
                rememberme: true,
            },
            isLoading: false,
            sendError: null,
        }
    },
    created() {
        if (localStorage.getItem('developer_mode') === 'true') {
            this.user = {
                email: 'test@test.ru',
                password: 'testtest',
                rememberme: true,
            }
        }
    },
    methods: {
        async login() {
            this.sendError = null
            this.isLoading = true
            Login.main({ email: this.user.email, password: this.user.password }).then(async res => {
                if (res.statusCode === 200) {
                    this.isLoading = false

                    const route = await redirectAfterAuth(res)
                    this.$router.push({ name: route }).then(() => { this.$router.go() })
                } else {
                    this.sendError = res.error ?? 'sending error'
                    this.isLoading = false
                }
            })
        },
        async restore() {
            console.log('restore')
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';
@import '@core/scss/base/bootstrap-extended/_forms.scss';

html[dir='rtl'] {
  svg.feather {
    transform: rotate(180deg);
  }
}

.options {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 360px) {
    flex-direction: row-reverse;
  }
}

.forget-me {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    margin-top: 18px;
  }

  @media only screen and (max-width: 375px) {
    flex-direction: column;
    align-items: start;
  }
}
.fg-pass {
  color: $red !important;
  font-size: 16px;
  padding: 0;
  background: 0 0;
  cursor: pointer;
  display: inline;
  border: none;
  position: relative;
  transition: all 0.2s ease-out;
  @media only screen and (max-width: 425px) {
    font-size: 14px;
  }
}
.remember-me {

    > label {
      margin-bottom: 0 !important;
      font-weight: bold;
      line-height: 27px;
      position: relative;
      line-height: 28px;

      @media only screen and (max-width: 1440px) {
        line-height: 22px;
        font-size: 16px;
      }

      @media only screen and (max-width: 425px) {
        line-height: 18px;
        font-size: 14px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 1px;
        width: 16px;
        height: 16px;
        border: 1px solid #000;
        background: #fff;
        box-sizing: border-box;
        @media only screen and (max-width: 425px) {
          top: 3px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 7px;
        left: 1px;
        width: 16px;
        height: 16px;
        transition: all 0.2s ease-out;
        background: url(@/assets/images/ocm/registration/check.png) no-repeat
          center;
        background-size: 100% 100%;
        opacity: 0;
        transition: all 0.2s ease-out;
        @media only screen and (max-width: 425px) {
          top: 3px;
        }
      }
      &:hover {
        &:after {
          opacity: 0.4;
        }
      }
    }

  &-checked {
    &:after {
      opacity: 1 !important;
    }
  }
}

.custom-input {
  margin-bottom: 0 !important;
}

.forgot-label {
    font-weight: bold;
    line-height: 30px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline 1px $primary;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
      line-height: 28px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 28px;
    }
    @media only screen and (max-width: 360px) {
      margin-bottom: 5px;
    }
}
.form-error {
  position: absolute;
}
</style>
