<template>

  <custom-modal
    key='loginModal'
    :width='600'
    :toggle='toggle'
    @closeModal="$emit('closeModal')"
    @openModal="$emit('openModal')"
  >
    <template
      #content
    >
      <div
        class='d-flex align-items-center'
      >
        <div
          class='px-xl-2 mx-auto'
        >
          <b-card-title class='mb-1 text-primary'>
            {{ $t('Forgot Password') }}? 🔒
          </b-card-title>
          <b-card-text class='mb-2 text-primary'>
            {{ $t("Enter your email and we'll send you instructions to reset your password") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref='simpleRules'>
            <b-form
              class='auth-forgot-password-form mt-2'
              @submit.prevent='validationForm'
            >

              <c-input
                id='reset-e-mail'
                type='email'
                :value='user.email'
                :placeholder='$t("E-mail")'
                @changeValue='(newValue) => (userEmail = newValue)'
              />

              <div class='options'>
                <div />

                <b-link href='/#signin'>
                  <div class='signin-label'>
                    {{ $t('Sign in') }}?
                  </div>
                </b-link>

              </div>

              <c-button
                :title="$t('Send reset link')"
              />

              <!-- <timer
                v-if='timer.visible'
                :start-time='startTime'
                :title='$t("wait before trying again") + " " + " "'
                :sub-title='$t("sec")'
                :center='true'
                @timeOver='timeOver'
              /> -->

            </b-form>
          </validation-observer>
        </div>
      </div>
    </template>
  </custom-modal>
</template>

<script>
import CButton from '@components/CButton.vue'
import CInput from '@components/CInput.vue'
import {
    BCardText,
    BCardTitle,
    BForm,
    BLink,
} from 'bootstrap-vue'
import RandExp from 'randexp'
import { ValidationObserver } from 'vee-validate'
import CustomModal from './CustomModal.vue'

export default {
    components: {
        CButton,
        CustomModal,
        CInput,
        BCardText,
        ValidationObserver,
        BForm,
        BCardTitle,
        BLink,
    },
    props: {
        toggle: {
            type: Boolean,
            require: true,
            default: false,
        },
    },
    setup() {
        return {
            RandExp,
        }
    },
    data() {
        return {
            user: {
                email: '',
                password: '',
                rememberme: false,
            },
            timer: {
                visible: false,
            },
            startTime: 59,
        }
    },
    created() {
        if (localStorage.getItem('developer_mode') === 'true') {
            this.user = {
                email: new RandExp(/[a-z]{6}^(@mail\.ru)$/gm).gen(),
                password: new RandExp(/[a-z]{6}^[1-9]{2}^(!)^[A-Z]{1}$/gm).gen(),
                rememberme: true,
            }
        }
    },
    methods: {
        async login() {
            console.log('login')
        },
        async restore() {
            console.log('restore')
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';
@import '@core/scss/base/bootstrap-extended/_forms.scss';

.m-login {
  &__button {
    margin-top: 30px;

  }
  &__label {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.brand-logo {
  svg {
    height: 28px;
    width: 40.95px;
  }
}

html[dir='rtl'] {
  svg.feather {
    transform: rotate(180deg);
  }
}
@media (min-width: 992px) and (max-width: 1260px) {
  .recaptcha {
    transform: scale(0.65)!important;
    transform-origin: 0 0!important;
  }
}
.recaptcha {
  transform: scale(0.88);
  transform-origin: 0 0;
}

.options {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 360px) {
    flex-direction: row-reverse;
  }
}

.signin-label {
    font-weight: bold;
    line-height: 30px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: underline 1px $primary;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
      line-height: 28px;
    }
    @media only screen and (max-width: 425px) {
      font-size: 14px;
      line-height: 28px;
    }
    @media only screen and (max-width: 360px) {
      margin-bottom: 5px;
    }
}
</style>
