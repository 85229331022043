import '@/libs/acl'
import '@/libs/clipboard'
import i18n from '@/libs/i18n'
import '@/libs/portal-vue'
import '@axios'
import VueGtag from '@gtm-support/vue2-gtm'
import * as Sentry from "@sentry/vue"
import PortalVue from 'portal-vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.component('VueSkeletonLoader', VueSkeletonLoader)

Vue.use(PortalVue)
require('@core/scss/core.scss')

if (!process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: process.env.SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            Sentry.replayIntegration(),
        ],
        trackComponents: true,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}

Vue.config.productionTip = false

// Подключаем googleAnalytics и yandexMetrica
Vue.use(VueGtag, {
    id: process.env.VUE_APP_GTM_ID,
    defer: true,
    enabled: true,
    loadScript: true,
    vueRouter: router,
    debug: false,
    trackOnNextTick: false,
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
