// eslint-disable-next-line import/prefer-default-export
export const suggestionsData = {
    banks: [
        'ACB',
        'BIDV',
        'Vietcombank',
        'Agribank',
        'Tpbank',
        'Techcombank',
        'Dong A',
        'Vietinbank',
        'Sacombank',
        'Bac A',
    ],

    cities_vn: [
        'Cần Thơ',
        'Da Nang',
        'Haiphong',
        'Hanoi',
        'Ho Chi Minh City',
        'Thủ Đức',
        'Bà Rịa',
        'Bạc Liêu',
        'Bảo Lộc',
        'Bắc Giang',
        'Bắc Kạn',
        'Bắc Ninh',
        'Biên Hòa',
        'Bến Tre',
        'Buôn Ma Thuột',
        'Cẩm Phả',
        'Cà Mau',
        'Cam Ranh',
        'Cao Bằng',
        'Cao Lãnh',
        'Châu Đốc',
        'Chí Linh',
        'Dĩ An',
        'Đà Lạt',
        'Điện Biên Phủ',
        'Đông Hà',
        'Đồng Hới',
        'Đồng Xoài',
        'Gia Nghĩa',
        'Hà Giang',
        'Hà Tiên',
        'Hà Tĩnh',
        'Hạ Long',
        'Hải Dương',
        'Hòa Bình',
        'Hội An',
        'Huế',
        'Hưng Yên',
        'Kon Tum',
        'Lai Châu',
        'Lạng Sơn',
        'Lào Cai',
        'Long Khánh',
        'Long Xuyên',
        'Móng Cái',
        'Mỹ Tho',
        'Nam Định',
        'Ngã Bảy',
        'Nha Trang',
        'Ninh Bình',
        'Phan Rang–Tháp Chàm',
        'Phan Thiết',
        'Phủ Lý',
        'Phú Quốc',
        'Phúc Yên',
        'Pleiku',
        'Quảng Ngãi',
        'Quy Nhơn',
        'Rạch Giá',
        'Sa Đéc',
        'Sầm Sơn',
        'Sóc Trăng',
        'Sơn La',
        'Sông Công',
        'Tam Điệp',
        'Tam Kỳ',
        'Tân An',
        'Tân Uyên',
        'Tây Ninh',
        'Thái Bình',
        'Thái Nguyên',
        'Thanh Hóa',
        'Thủ Dầu Một',
        'Thuận An',
        'Trà Vinh',
        'Tuy Hòa',
        'Tuyên Quang',
        'Uông Bí',
        'Vị Thanh',
        'Việt Trì',
        'Vinh',
        'Vĩnh Long',
        'Vĩnh Yên',
        'Vũng Tàu',
        'Yên Bái',
    ],

    cities_en: [

        'Can Tho',
        'Danang',
        'Haiphong',
        'Hanoi',
        'Ho Chi Minh City',
        'Thu Duc',
        'Ba Ria',
        'Bac Lieu',
        'Bao Loc',
        'Bac Giang',
        'Bac Kan',
        'Bac Ninh',
        'Bien Hoa',
        'Ben tre',
        'Buon Ma Thuot',
        'Cam Pha',
        'Ca Mau',
        'Orange line',
        'As tall as',
        'Cao Lanh',
        'Continental Director',
        'Chi Linh',
        'Di An',
        'Da Lat',
        'Dien Bien Phu',
        'Dong Ha',
        'Dong Hoi',
        'Dong Xoai',
        'Gia Nghia',
        'Ha Giang',
        'Ha Tien',
        'Ha Tinh',
        'Ha Long',
        'Hai Duong',
        'Peace',
        'Hoi An',
        'Hue',
        'Hung Yen',
        'Kon Tum',
        'Lai Chau',
        'Lang Son',
        'Lao Cai',
        'Long Khanh',
        'Long Xuyen',
        'Mong Cai',
        'My Tho',
        'Nam Dinh',
        'Seventh Crossroads',
        'Nha Trang',
        'Ninh Binh',
        'Phan Rang–Thap Cham',
        'Phan Thiet',
        'Phu Ly',
        'Phu Quoc',
        'Phuc Yen',
        'Pleiku',
        'Quang Ngai',
        'Quy Nhon',
        'Rach Gia',
        'Sa Dec',
        'Sam Son',
        'Soc Trang',
        'Son La',
        'Cong River',
        'Tam Diep',
        'Tam Ky',
        'Tan An',
        'Tan Uyen',
        'Tay Ninh',
        'Peaceful',
        'Thai Nguyen',
        'Thanh Hoa',
        'Thu Dau Mot',
        'Thuan An',
        'Tra Vinh',
        'Tuy Hoa',
        'Tuyen Quang',
        'Uong Bi',
        'Vi Thanh',
        'Viet Tri',
        'Vinh',
        'Vinh Long',
        'Vinh Yen',
        'Vung Tau',
        'Yen Bai',
    ],
}
