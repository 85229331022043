<template>
  <b-modal
    id='custom_modal'
    v-model='modalToggle'
    no-fade
    hide-header-close
    centered
    scrollable
    hide-header
    hide-footer
    class='modal'
    @show='open'
    @hidden='close'
    @ok='close'
  >
    <slot name='modal-header'>
      <div class='modal-close__wrap'>
        <div
          class='modal-close'
          @click='close'
          @keyDown='close'
        >
          <div class='line-p line-p1' />
          <div class='line-p line-p2' />
        </div>
      </div>
    </slot>
    <div class='c-modal'>
      <div class='c-modal__content'>
        <slot name='content' />
      </div>

      <div
        v-if='type === "dialog"'
        class='c-modal__buttons'
      >
        <c-button
          class='c-modal__button'
          :title='$t("Accept")'
          @onClick='$emit("onAccept")'
        />
        <c-button
          class='c-modal__button'
          :title='$t("Refuse")'
          type='outlined'
          @onClick='$emit("onRefuse")'
        />
      </div>
    </div>

    <!-- <slot name='modal-ok' /> -->

  </b-modal>
</template>

<script>
import CButton from "@components/CButton.vue"
import { BModal } from 'bootstrap-vue'

export default {
    components: {
        BModal,
        CButton,
    },
    props: {
        toggle: {
            type: Boolean,
            require: false,
        },
        type: {
            type: String,
            require: false,
            default: 'modal',
        },
    },

    computed: {
        modalToggle: {
            get() {
                return this.toggle
            },
            set() {
                return this.toggle
            },
        },
    },

    methods: {
        close() {
            this.$emit('closeModal')
        },
        open() {
            this.$emit('openModal')
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/_variables.scss';
#custom_modal___BV_modal_outer_ {
    z-index: 2000 !important;
}

.modal-content {
    border-radius: 20px !important;
    padding: 20px;
    width: 100%;
    max-height:  80% !important;
    @media only screen and (max-width: 640px) {
        border-radius: 0 !important;
        border: none !important;
        width: 100vw !important;
        max-height: 100%!important;
        height: 100%;

    }
}
.modal-dialog {
    max-width: 600px !important;
    @media only screen and (max-width: 640px) {
        width: 100vw;
        margin: 0 !important;
        max-width: 100vw !important;
    }
    @media only screen and (min-width: 640px) {
        margin: 1.75rem auto;
    }
}
.modal-body {
    padding: 0 !important;
    overflow: auto;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: unset !important;
}
.modal-dialog-scrollable {
    max-height: 100vh !important;
}

.c-modal {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    &__content {
        overflow: -moz-scrollbars-vertical;
        overflow-y: scroll;
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        -webkit-box-shadow: -6px -11px 8px -6px rgba(34, 60, 80, 0.06);
        -moz-box-shadow: -6px -11px 8px -6px rgba(34, 60, 80, 0.06);
        box-shadow: -6px -11px 8px -6px rgba(34, 60, 80, 0.06);
    }

    &__button {
        margin: 0;
        width: calc(50% - 10px);
        .c-button {
            margin: 20px 0 0 !important;
        }
    }
}
.modal-close {
    right: 0;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    cursor: pointer;
    display: block;
    z-index: 1000;

    @media only screen and (max-width: 1200px) {
        width: 22px;
        height: 22px;
    }
    &__wrap {
        height: 50px;
    }
}
.line-p {
    width: 100%;
    height: 3px;
    top: 10px;
    position: absolute;
    background-color: $primary;
    transition: all 0.4s ease-out;
    border-radius: 10px;
    @media only screen and (max-width: 1200px) {
        top: 8px;
    }
}
.line-p1 {
    left: 0;
    transform: rotate(45deg);
}
.line-p2 {
    right: 0;
    transform: rotate(-45deg);
}
.fade {
    background-color: rgba(0, 0, 0, 0.6) !important;
}
.modal {
    overflow-y: hidden !important;
    @media (max-width: 576px) {
        padding-right: 0;
        padding-left: 0;
    }
}
</style>
